define("discourse/plugins/discourse-hcaptcha/discourse/components/h-captcha", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/runloop", "@ember/service", "discourse/lib/load-script", "I18n", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _runloop, _service, _loadScript, _I18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div id="h-captcha-field" class="h-captcha" data-sitekey={{@sitekey}}></div>
  
  {{#if this.hCaptchaConfigError}}
    <div class="alert alert-error">
      {{this.hCaptchaConfigError}}
    </div>
  {{/if}}
  
  {{#if this.hCaptchaService.submitFailed}}
    <InputTip @validation={{this.hCaptchaService.inputValidation}} />
  {{/if}}
  */
  {
    "id": "zEBd69Ep",
    "block": "[[[10,0],[14,1,\"h-captcha-field\"],[14,0,\"h-captcha\"],[15,\"data-sitekey\",[30,1]],[12],[13],[1,\"\\n\\n\"],[41,[30,0,[\"hCaptchaConfigError\"]],[[[1,\"  \"],[10,0],[14,0,\"alert alert-error\"],[12],[1,\"\\n    \"],[1,[30,0,[\"hCaptchaConfigError\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"hCaptchaService\",\"submitFailed\"]],[[[1,\"  \"],[8,[39,2],null,[[\"@validation\"],[[30,0,[\"hCaptchaService\",\"inputValidation\"]]]],null],[1,\"\\n\"]],[]],null]],[\"@sitekey\"],false,[\"div\",\"if\",\"input-tip\"]]",
    "moduleName": "discourse/plugins/discourse-hcaptcha/discourse/components/h-captcha.hbs",
    "isStrictMode": false
  });
  const HCAPTCHA_SCRIPT_URL = "https://hcaptcha.com/1/api.js?render=explicit";
  class HCaptcha extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "hCaptchaService", [_service.inject]))();
    #hCaptchaService = (() => (dt7948.i(this, "hCaptchaService"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "widgetId", [_tracking.tracked]))();
    #widgetId = (() => (dt7948.i(this, "widgetId"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "invalid", [_tracking.tracked], function () {
      return true;
    }))();
    #invalid = (() => (dt7948.i(this, "invalid"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "hCaptchaConfigError", [_tracking.tracked], function () {
      return "";
    }))();
    #hCaptchaConfigError = (() => (dt7948.i(this, "hCaptchaConfigError"), void 0))();
    hCaptcha;
    constructor() {
      super(...arguments);
      this.initializeHCaptcha(this.args.siteKey);
    }
    initializeHCaptcha(siteKey) {
      if (this.isHCaptchaLoaded()) {
        (0, _runloop.next)(() => {
          if (document.getElementById("h-captcha-field")) {
            this.renderHCaptcha(siteKey);
          }
        });
        return;
      }
      this.loadHCaptchaScript(siteKey);
    }
    isHCaptchaLoaded() {
      return typeof this.hCaptcha !== "undefined";
    }
    async loadHCaptchaScript(siteKey) {
      await (0, _loadScript.default)(HCAPTCHA_SCRIPT_URL);
      this.hCaptcha = window.hcaptcha;
      this.renderHCaptcha(siteKey);
    }
    renderHCaptcha(siteKey) {
      if (!this.isHCaptchaLoaded() || !this.args.siteKey) {
        this.hCaptchaConfigError = _I18n.default.t("discourse_hCaptcha.contact_system_administrator");
        return;
      }
      this.widgetId = this.hCaptcha.render("h-captcha-field", {
        sitekey: siteKey,
        callback: response => {
          this.hCaptchaService.token = response;
          this.hCaptchaService.invalid = !response;
        },
        "expired-callback": () => {
          this.hCaptchaService.invalid = true;
        }
      });
      this.hCaptchaService.registerWidget(this.hCaptcha, this.widgetId);
    }
    willDestroy() {
      super.willDestroy(...arguments);
      if (this.isHCaptchaLoaded()) {
        this.hCaptcha.reset(this.widgetId);
      }
    }
  }
  _exports.default = HCaptcha;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, HCaptcha);
});